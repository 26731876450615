import { Alert } from "@material-ui/lab";
import FilesIconAnalize from "app/main/files/FilesIconAnalize.js";

const showMessage = (item, type) => {
	if (item[`status`] === "rejected") {
		return (
			<Alert className="flex flex-row w-full" severity="error">
				Rejected
			</Alert>
		);
	}
};

const uploadFile = ({
	internalConfig = false,
	type = "pod_files",
	enabled = true,
	noLabel,
	isPublicView,
	isNative = false,
	isEnabledAnalizeIcon = false,
}) => {
	if (!enabled) return [];

	return [
		{
			key: type,
			type: "pattern",
			//label: noLabel ? "" : "Documents",
			pattern: {
				classes: noLabel ? { label: "-mt-8" } : { label: "pt-10 font-semibold text-14" },
				showAllDefault: true,
				showWarning: { show: !internalConfig, showMessage },
			},
			content: {
				items: [
					!internalConfig && {
						label: "",
						type: "component",
						builder: (item) => showMessage(item, type),
						message: {
							classes: "justify-center",
						},
					},
					{
						key: "file_id",
						type: "upload",
						label: "Document",
						required: true,
						field: {
							classes: {},
							variant: undefined,
						},
						upload: {
							url: isPublicView
								? "api/public/url/booking/carriers/:carrierId/files"
								: "api/booking/carriers/:carrierId/files",
							documentType: "trip",
							fileName: "file_id",
							type: "file_id",
							allowedFiles: [".pdf", ".jpg", ".jpeg", ".png"],
							params: {
								...(isPublicView ? { options_public_user_context: ":userId" } : {}),
							},
						},
					},
					{
						type: "group",
						group: {
							classes: {
								child0: isEnabledAnalizeIcon ? "w-full md:w-11/12" : "w-full md:w-12/12",
								child1: "w-full md:w-1/12",
							},
						},
						content: {
							items: [
								{
									key: "type",
									type: "select",
									required: true,
									label: "Document Type",
									defaultValue: "pod",
									select: { alignment: "horizontal" },
									options: [
										{
											value: "pod",
											label: "Bill Of Lading",
										},
										{
											value: "rate_confirmation",
											label: "Rate Confirmation",
										},
										{
											value: "proof_of_delivery",
											label: "Proof Of Delivery",
										},
										{
											value: "packing_list",
											label: "Packing List",
										},
										{
											value: "lumper_receipt",
											label: "Lumper Receipt",
										},
										{
											value: "dock/warehouse_receipt",
											label: "Dock/Warehouse Receipt",
										},
										{
											value: "scale_or_weight_receipt",
											label: "Scale or Weight Receipt",
										},
										{
											value: "cargo_picture",
											label: "Cargo Picture",
										},
										{
											value: "display_screen",
											label: "Display Screen",
										},
										{
											value: "other",
											label: "Other",
											showItems: [
												{
													required: true,
													key: "description",
													type: "text",
													field: {
														classes: {},
														variant: undefined,
													},
													label: "Description",
												},
											],
										},
									],
								},
								{
									key: "type",
									type: "message",
									builder: (f) => <FilesIconAnalize data={f.analize} style={{ icon: "text-24 mb-5 ml-5 mr-0" }} />,
									hidden: () => !isEnabledAnalizeIcon || isNative,
								},
							],
						},
					},
				],
			},
		},
	];
};

export { uploadFile };
