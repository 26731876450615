import { useMemo, useEffect, useState, forwardRef, useImperativeHandle, useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";

import { RATE_OPTIONS, DRIVER_NOTIFICATION_OPTIONS, TRIP_NOTIFICATION_OPTIONS } from "app/main/consts";
import { hideRate, onClickCreateUrl, getNextStatusExternal, STATUS_TRIPS } from "app/main/utils/tripsUtils";
import { openFormDialog, openLoadedFormDialog, refreshDialog, closeFormDialog } from "app/store/tools/formDialogSlice";
import {
	getRoleLabel,
	getUserTier,
	getAuthToken,
	isCarrierPermission,
	isInternalPermission,
	isDriver,
} from "app/services/LoginService";
import { useTripInformation } from "./hooks/useTripInformation";
import { handleFileUpload, handledLocationsUpdated } from "app/main/profile/trips/hooks/createTrip";
import { hasRequiredGateKeepers, processTierRestrictions, roleRestrictions } from "app/main/utils/rolesUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { SmarthopConfirmDialog } from "@smarthop/form";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import {
	saveTripRC,
	sendItinerary,
	sendBolNotification,
	updateTrip,
	updateTripStatus,
	sendBrokerUpdate,
} from "app/services/tripsServices";
import { buildLink } from "app/services/fileServices";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";
import TrackingCellView from "@smarthop/list/views/TrackingCellView";
import TripCreateTranscription from "./TripCreateTranscription";
import CostsBreakdownPage from "app/main/profile/cost-structure/calculator/CostsBreakdownPage";
import TripDescription from "./TripDescription";
import { uploadFiles } from "app/main/multi-file-upload/MultiFileUploadView";
import uniqid from "uniqid";
import TripPayrollPage from "../users/TripPayrollPage";
import { isEnabled } from "app/services/featureStorageService";
import TripStatusSelector from "./TripStatusSelector";

import { tripCommentsList, loadHistoryList } from "@smarthop/list";
import TripCommentPanel from "app/main/profile/trips/TripCommentPanel";
import WarningConfirmDialog from "../../common/WarningConfirmDialog";
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

// Segment
import { CALENDAR_EVENT } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

const _ERROR_PARSE_DOC = `We failed to parse uploaded documents, it's still uploaded but please transcribe document manually.`;
const _SUCCESS_PARSE_DOC = `We successfully parsed uploaded documents, please verify all information before saving.`;
const _DEFAULT_TRANSCRIPTION = { flag: false, message: null, type: null, showEdit: false, optionsText: {} };
const _FAILED_TRANSCRIPTION = {
	flag: true,
	showEdit: false,
	optionsText: { accept: "Transcribe Manually", close: "Cancel" },
	error: true,
};
const _SUCCESS_TRANSCRIPTION = {
	flag: true,
	showEdit: true,
	message: _SUCCESS_PARSE_DOC,
	optionsText: { accept: "Save and Verify", edit: "Edit transcription", close: "Cancel" },
};

const TripView = forwardRef(({ nativeMobile, dataIds, onProgress, setTitle, onLoading }, ref) => {
	useEffect(() => {
		setTitle?.("Trip Details");
		if (dataIds?.fromCalendar) {
			createTrackOrPage(CALENDAR_EVENT, { type: "trip" }, "track");
		}
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const user = useSelector(({ auth }) => auth.user);

	const tripId = dataIds?.tripId;
	const isExternal = dataIds?.isExternal;
	const readOnly = dataIds.readOnly;
	const carrierId = dataIds?.carrierId;
	const reactNative = window?.ReactNativeWebView ?? false;

	const isLogged = !!getAuthToken();
	const userTier = getUserTier();
	const processRestrictions = !processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier });
	const basicRestrictions = !processTierRestrictions({ restrictions: ["TIER_BASIC"], tier: userTier });

	const role = useMemo(() => getRoleLabel(), []);
	const isAdmin = role === "administrator";
	const isManager = ["administrator", "carrier", "carrier_manager"].includes(role);
	const isInternal = useMemo(() => isInternalPermission(role), [role]);
	const isCarrier = useMemo(() => isCarrierPermission(role), [role]);
	const isCarrierDriver = useMemo(() => isDriver(), []);
	const isPublicView = useMemo(() => !!dataIds?.public || dataIds?.public === "true", [dataIds]);
	const [trip, tripPlan, tripInvoice, hidePrice, error] = useTripInformation(dataIds, onLoading, nativeMobile);

	// Payroll and Invoicing features check
	const isTripInvoicingEligible = trip?.enterprise_features?.invoicingModuleOnboarding === "COMPLETE";
	const invoiceHasAccessView = hasRequiredGateKeepers(user, { permission_invoice_access: "viewer" });
	const invoiceHasAccessEdit = hasRequiredGateKeepers(user, { permission_invoice_access: "editor" });
	const isPayrollEnabled = trip?.enterprise_features?.payroll_module_flag;
	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const hasRatesOnPermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;
	const hasMessagingPermission = ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_messaging_on: true })
		: true;
	const isProfitDisabled = !!trip?.carrierNotValid || !hasRatesOnPermission;

	const [openWarningDialog, setOpenWarningDialog] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [tripUpdated, setTripUpdated] = useState(null);
	const [dialogTranscription, setDialogTranscription] = useState(null);
	const [rcFile, setRcFile] = useState(null);
	const [openDialogAlert, setDialogAlert] = useState({
		flag: false,
		message: null,
		type: null,
		optionsText: {},
	});
	const [paywallDialog, setPaywallDialog] = useState({});

	const ignoreWarnings = useRef(false);

	const onClickNextState = useCallback(() => {
		const newStatus = getNextStatusExternal(trip);
		setProcessing(true);
		updateTripStatus({
			carrierId: trip.carrier,
			tripId: trip._id,
			status: newStatus.value,
			ignoreWarnings: ignoreWarnings.current,
		})
			.then(
				(data) => {
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					setProcessing(false);
					showSnackbar(snackbar, "Trip status updated", "success");
				},
				(err) => {
					setProcessing(false);
					const tripWarnings = err?.errors?.[0]?.metadata?.warnings;
					if (!tripWarnings?.length) {
						showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating the trip status", "error");
					} else {
						setOpenWarningDialog({
							warnings: tripWarnings,
							title: "Are you sure you want to continue?",
							message: err?.errors?.[0].message,
						});
					}
				}
			)
			.catch((err) => {
				setProcessing(false);
				console.log("ERROR", err);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating the trip status", "error");
			});
	}, [dispatch, setProcessing, snackbar, trip, ignoreWarnings]);

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			if (hasEditTripsPermission && !readOnly) {
				btns.edit = {
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								viewId: "TRIP_EDIT_VIEW",
								mode: "EDIT",
								dataIds: { tripId, carrierId, isExternal },
							})
						);
					},
					title: "Edit Trip",
				};

				if (hasRatesOnPermission) {
					btns.duplicate = {
						onClick: () => {
							dispatch(
								openLoadedFormDialog({
									viewId: "TRIP_EDIT_VIEW",
									mode: "CREATE",
									dataIds: {
										carrierId,
										isExternal: dataIds?.isExternal,
										rcDataEditable: {
											...trip,
											// Remove some fields
											_id: undefined,
											id: undefined,
											status: undefined,
											status__view: undefined,
											load_id: undefined,
											eta: undefined,
											pickup_date: undefined,
											delivery_date: undefined,
											pickup_window_date: undefined,
											delivery_window_date: undefined,
											accesorials: [],
											logistics_costs: [],
											pod_files: [],
											snapshots: undefined,
											rate_con_file: [],
											pod_file: [],
											rate_con_file__flag: false,
											pod_file__flag: false,
										},
										rcDataParsed: {},
										mode: "CREATE",
										tripId: null,
										DANGEROUS_onDone: (data) => {
											if (data) {
												// Close this caller
												dispatch(closeFormDialog());
											}
										},
									},
								})
							);
						},
						title: "Duplicate Trip",
						icon: "content_copy",
					};
				}
			}

			if (isInternal || isCarrier) {
				btns.share = {
					onSelect: (shareOption) => {
						onClickCreateUrl({ carrierId, tripId, form: "TRIP_VIEW", snackbar, option: hideRate(shareOption) });
					},
					options: RATE_OPTIONS,
					title: "Share",
				};
			}

			const status = trip?.status;
			if (isInternal || isCarrier) {
				if (status && status === "Delivered" && isEnabled("SEND_DRIVER_BOL_NOTIFICATION")) {
					btns.sendItinerary = {
						options: DRIVER_NOTIFICATION_OPTIONS,
						onSelect: async (selectedOption) => {
							if (selectedOption === "Send Driver Itinerary") {
								return sendItinerary(carrierId, tripId)
									.then(() => {
										dispatch(incrementDataRevision({ event: "profileRevision" }));
										showSnackbar(snackbar, "Itinerary Sent", "success");
									})
									.catch((e) => showSnackbar(snackbar, e?.errors?.[0]?.message ?? e.message, "error"));
							} else if (selectedOption === "Send BOL Notification") {
								const onSubmitNotification = () => {
									return sendBolNotification(carrierId, tripId)
										.then(() => {
											dispatch(incrementDataRevision({ event: "profileRevision" }));
											showSnackbar(snackbar, "BOL Notification Sent", "success");
										})
										.catch((e) => showSnackbar(snackbar, e?.errors?.[0]?.message ?? e.message, "error"));
								};

								if (processRestrictions) {
									setPaywallDialog({
										open: true,
										dataIds: {
											type: "driver_notification_restriction",
											data: { dataIdsInherited: dataIds, callback: onSubmitNotification, callbackKey: "TRIP_VIEW" },
										},
									});
									return;
								} else {
									return onSubmitNotification();
								}
							}
							showSnackbar(snackbar, "Option not available", "error");
						},
						icon: "send",
					};
				} else if (status && status !== "Booked" && status !== "Not Booked" && status !== "Delivered") {
					btns.sendNotification = {
						onSelect: (selectedOption) => {
							if (selectedOption === "Send Driver Itinerary") {
								return sendItinerary(carrierId, tripId)
									.then(() => {
										dispatch(incrementDataRevision({ event: "profileRevision" }));
										showSnackbar(snackbar, "Itinerary Sent", "success");
									})
									.catch((e) => showSnackbar(snackbar, e?.errors?.[0]?.message ?? e.message, "error"));
							} else if (selectedOption === "Send Broker Update") {
								const onSubmitNotification = () => {
									return sendBrokerUpdate(carrierId, tripId)
										.then(() => {
											dispatch(incrementDataRevision({ event: "profileRevision" }));
											showSnackbar(snackbar, "Broker update Sent", "success");
										})
										.catch((e) => showSnackbar(snackbar, e?.message ?? e?.errors?.[0]?.message, "error"));
								};

								if (basicRestrictions) {
									setPaywallDialog({
										open: true,
										dataIds: {
											type: "automate_functionality_restriction",
											data: { dataIdsInherited: dataIds, callback: onSubmitNotification, callbackKey: "TRIP_VIEW" },
										},
									});
									return;
								} else {
									return onSubmitNotification();
								}
							}
							showSnackbar(snackbar, "Option not available", "error");
						},
						options: TRIP_NOTIFICATION_OPTIONS,
						title: "Send notification",
					};
				}
			}

			if (hasMessagingPermission && trip?.truck && !dataIds?.openChat) {
				btns.chat = {
					onClick: () => {
						dispatch(
							openFormDialog({
								viewId: "CHAT_APP_PREVIEW_TRUCK_EXTERNAL",
								dataIds: { truckId: trip?.truck, carrierId: trip?.carrier },
							})
						);
					},
					icon: "chat_bubble_outline",
				};
			}

			const [fileId] = trip?.rate_con_file ?? [];
			if (isAdmin && fileId) {
				btns.save = {
					onClick: () => {
						dispatch(
							openFormDialog({
								viewId: "TRIP_TEST_DATA_VIEW",
								dataIds: { tripId, carrierId, fileId, urlFilePreview: buildLink(fileId) },
							})
						);
					},
					title: "Save for testing",
				};
			}

			dispatch(refreshDialog());
			return btns;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[trip, dataIds, dispatch]
	);

	useEffect(() => {
		if (!trip) return;
		if (onProgress) onProgress(processing);
	}, [processing, onProgress, trip]);

	const rcUploadEnable = useMemo(
		() => isInternal || isCarrier || isCarrierDriver,
		[isInternal, isCarrier, isCarrierDriver]
	);
	const openAddEventDialog = () => {
		let addDialog = { viewId: "TRIP_TRACKING", mode: "CREATE", dataIds: { carrierId, tripId, data: trip } };
		dispatch(openLoadedFormDialog(addDialog));
	};

	const handleDownload = (value) => {
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: buildLink(value[0]),
				},
			})
		);
	};

	const onFileUpload = async (e) => {
		setProcessing(true);
		try {
			let response = await handleFileUpload(carrierId, trip, e.target.files);
			e.target.value = "";

			setRcFile(response?.rate_con_file);

			if (processRestrictions) {
				setTripUpdated(response);
				setProcessing(false);
				setDialogTranscription(_DEFAULT_TRANSCRIPTION);
				dispatch(
					openLoadedFormDialog({
						viewId: "TRIP_EDIT_VIEW",
						dataIds: { tripId, carrierId, isExternal, rcDataEditable: response },
					})
				);
				return;
			}

			if (response?.errors?.length > 0) {
				_FAILED_TRANSCRIPTION.message = `${_ERROR_PARSE_DOC} (${
					response?.errors?.[0]?.message ?? response?.errors?.message
				})`;

				setProcessing(false);
				setDialogTranscription(_FAILED_TRANSCRIPTION);
				return;
			}

			setTripUpdated(response);

			const locationCalc = await handledLocationsUpdated(response, trip);
			if (locationCalc) {
				response = { ...response, ...locationCalc };

				let message = `Recalculated empty miles (${locationCalc.emptymiles}), loaded miles (${locationCalc.miles}), and RPM (${locationCalc.rpm})`;
				showSnackbar(snackbar, message, "success");
			} else {
				const message = "Failed to racalculate miles...";
				showSnackbar(snackbar, message, "error");
			}

			setTripUpdated(response);
			setProcessing(false);
			setDialogTranscription(_SUCCESS_TRANSCRIPTION);
		} catch (error) {
			setProcessing(false);
			showSnackbar(snackbar, `There was an error uploading file`, "error", 5000);
		}
	};

	const onFileUploadGPT = async (e) => {
		const uploadId = uniqid();
		const response = await uploadFiles(Array.from(e.target.files), dispatch, uploadId, true, carrierId, true);
		const { fileId } = response;
		const rcDataEditable = { ...trip };
		rcDataEditable.rate_con_file = [fileId];
		rcDataEditable.rate_con_file__flag = true;
		dispatch(
			openLoadedFormDialog({
				viewId: "TRIP_EDIT_VIEW",
				dataIds: { tripId, carrierId, isExternal, rcDataEditable, uploadId },
			})
		);
	};

	const handleEditTranscription = () => {
		try {
			setDialogTranscription(_DEFAULT_TRANSCRIPTION);
			setProcessing(true);
			saveTripRC({ rcFile, tripId, carrierId }).then(
				(res) => {
					setProcessing(false);
					dispatch(
						openLoadedFormDialog({
							viewId: "TRIP_EDIT_VIEW",
							dataIds: { tripId, carrierId, isExternal, rcDataEditable: tripUpdated },
						})
					);
					setRcFile(null);
					setTripUpdated(null);
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(incrementDataRevision({ event: "profileRevision" }));
				},
				(err) => {
					setRcFile(null);
					setProcessing(false);
					showSnackbar(snackbar, `There was an error saving rc file`, "error", 5000);
				}
			);
		} catch (err) {
			setRcFile(null);
			setProcessing(false);
			setDialogTranscription(_DEFAULT_TRANSCRIPTION);
			showSnackbar(snackbar, `There was an error saving rc file`, "error", 5000);
		}
	};

	const handleSaveTrip = () => {
		try {
			setDialogTranscription(_DEFAULT_TRANSCRIPTION);
			setProcessing(true);
			setRcFile(null);
			updateTrip(tripId, carrierId, tripUpdated).then(
				(res) => {
					setTripUpdated(null);
					setProcessing(false);
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(incrementDataRevision({ event: "profileRevision" }));
				},
				(err) => {
					setTripUpdated(null);
					setProcessing(false);
					showSnackbar(
						snackbar,
						`There was an error saving trip transcription.(${err?.errors?.[0]?.message ?? err.message})`,
						"error",
						5000
					);
				}
			);
		} catch (err) {
			setRcFile(null);
			setProcessing(false);
			setDialogTranscription(_DEFAULT_TRANSCRIPTION);
			showSnackbar(
				snackbar,
				`There was an error saving trip transcription.(${err?.errors?.[0]?.message ?? err.message})`,
				"error",
				5000
			);
		}
	};

	const onCloseDialogTranscription = () => {
		setRcFile(null);
		setTripUpdated(null);
		setDialogTranscription(_DEFAULT_TRANSCRIPTION);
	};

	const openPaywallView = () => {
		dispatch(
			openFormDialog({
				viewId: "PAYWALL_VIEW",
				dataIds: {
					type: "automate_functionality_restriction",
					data: { dataIdsInherited: { ...dataIds, initTab: "TRACKING" }, callbackKey: "TRIP_VIEW" },
				},
			})
		);
	};

	if (!trip || error) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={!trip} error={error} />;
	}

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			processing={processing}
			sideComponents={[
				{
					key: "TRIP_MAP_" + trip._id,
					type: "MAP",
					tabKeys: ["DETAILS", "TRACKING"],
					params: {
						locations: trip?.planLocations ?? trip?.all_locations,
						showFuelLocationsButton: isEnabled("SHOW_FUEL_LOCATIONS_BUTTON"),
						loadId: trip?.load_id,
						carrierId,
						plan: tripPlan,
						truck: {
							label: trip?.truck__view?.label,
							lastLocation: trip?.truck__view?.metadata?.truck?.lastLocation,
							load_id: trip?.load_id,
						},
						truckLocation:
							STATUS_TRIPS.active.includes(trip.status) || STATUS_TRIPS.delivered.includes(trip.status)
								? trip?.truckLocation ?? trip?.last_location_coordinates
								: undefined,
					},
				},
			]}
			initTab={dataIds.initTab}
			tabComponents={[
				{
					key: "DETAILS",
					title: "Details",
					component: (
						<div className="flex flex-col w-full">
							{trip && (
								<TripDescription
									isPublicView={isPublicView}
									priceRestricted={hidePrice}
									load={trip}
									invoice={!isPublicView && isTripInvoicingEligible && invoiceHasAccessView ? tripInvoice : null}
									processing={processing}
								/>
							)}
						</div>
					),
				},
				{
					key: "COMMENTS",
					title: "Comments",
					type: "LIST",
					hideOnDesktop: isPublicView,
					hideOnMobile: isPublicView,
					header: <TripCommentPanel tripId={dataIds.tripId} />,
					params: {
						config: tripCommentsList(nativeMobile),
						dataIds,
						renderEmptyListView: () => "Add your first comment to the trip",
					},
				},
				{
					key: "TRACKING",
					title: "Tracking",
					labelView: STATUS_TRIPS.active.includes(trip.status) && (
						<div
							className={
								"w-8 h-8 ml-4 rounded-full -mt-4" +
								(trip.tracking__view?.metadata?.outdated ? " bg-red " : " bg-green animate-pulse ")
							}
						/>
					),
					type: "LIST",
					hideOnDesktop: isPublicView,
					hideOnMobile: isPublicView,
					headerHeight: processRestrictions ? 105 : null,
					header: (
						<>
							{processRestrictions && (
								<div className="w-full pb-6">
									<Alert severity="info">
										Want to save time on manual trip updates?
										<Link className="cursor-pointer px-4" onClick={() => openPaywallView()}>
											<span className="text-primary font-semibold underline">Click here</span>
										</Link>
										to learn how we can automate trip status changes.
									</Alert>
								</div>
							)}
							<div
								className={`flex flex-row w-full items-center ${
									!STATUS_TRIPS.pending.includes(trip?.status) ? "justify-between" : "justify-end"
								} border-b-1 broger-grey mb-4`}
							>
								{!STATUS_TRIPS.pending.includes(trip?.status) && (
									<TrackingCellView
										item={trip}
										view={trip.tracking__view}
										config={{ showETA: true, disableLink: true }}
									/>
								)}
								<div className="flex flex-row space-x-4">
									{!isCarrierDriver && (
										<div className="flex flex-col pb-6">
											<Button
												variant="outlined"
												className={"bg-grey-100 text-12 border-1 whitespace-nowrap"}
												onClick={() => openAddEventDialog()}
											>
												Add Event
											</Button>
										</div>
									)}
									{isAdmin && (
										<div className="flex flex-col pb-6">
											<Button
												variant="outlined"
												className={"bg-grey-100 text-12 border-1"}
												onClick={() =>
													dispatch(
														openLoadedFormDialog({
															viewId: "DEBUG_TRACKING",
															dataIds: { carrierId, tripId, trip: trip },
														})
													)
												}
											>
												Debug Tools
											</Button>
										</div>
									)}
								</div>
							</div>
						</>
					),
					params: {
						config: loadHistoryList({ publicView: isPublicView }),
						dataIds: { carrierId, tripId },
						renderEmptyListView: () => "No Tracking Events Yet",
					},
				},
				{
					key: "PROFIT",
					title: "Profit",
					hideOnDesktop: isPublicView || (!isInternal && !isCarrier) || isProfitDisabled,
					hideOnMobile: isPublicView || (!isInternal && !isCarrier) || isProfitDisabled,
					component: (
						<div className="flex flex-col w-full">
							<CostsBreakdownPage data={trip} isView={true} />
						</div>
					),
				},
				{
					key: "PAYROLL",
					title: "Payroll",
					hideOnDesktop: isPublicView || !isPayrollEnabled || !trip?.payroll_elegible,
					hideOnMobile: true,
					component: (
						<div className="flex flex-col w-full">
							<TripPayrollPage trip={trip} dataIds={dataIds} isView={true} />
						</div>
					),
				},
			]}
			footerActions={[
				{
					key: "EDIT_TRIP",
					title: "Edit Trip",
					style: { align: "LEFT", icon: "edit" },
					hideOnDesktop: readOnly || !hasEditTripsPermission || !isLogged,
					hideOnMobile: readOnly || !hasEditTripsPermission || !isLogged,
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								viewId: "TRIP_EDIT_VIEW",
								mode: "EDIT",
								dataIds: { tripId, carrierId, isExternal },
							})
						);
					},
				},
				{
					key: "ADD_RC",
					title: "Upload RC",
					style: { align: "LEFT", icon: "publish" },
					hideOnDesktop: !rcUploadEnable || trip.rate_con_file__flag || !hasRatesOnPermission || !isLogged,
					hideOnMobile: !rcUploadEnable || trip.rate_con_file__flag || !hasRatesOnPermission || !isLogged,
					component: (
						<input
							onChange={!reactNative ? onFileUploadGPT : onFileUpload}
							id="ADD_RC"
							type="file"
							className="hidden"
						/>
					),
				},
				{
					key: "VIEW_RC",
					title: "View RC",
					style: { align: "LEFT", icon: "request_quote" },
					hideOnDesktop: !trip.rate_con_file__flag || !trip.rate_con_file || !hasRatesOnPermission || !isLogged,
					hideOnMobile: !trip.rate_con_file__flag || !trip.rate_con_file || !hasRatesOnPermission || !isLogged,
					onClick: () => {
						handleDownload(trip.rate_con_file, "rate_con_file");
					},
				},
				{
					key: "UPLOAD_FILES",
					title: "Upload Files",
					style: { align: "LEFT", icon: "note_add" },
					hideOnDesktop: !rcUploadEnable && isLogged,
					hideOnMobile: !rcUploadEnable && isLogged,
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								viewId: "UPLOAD_FILES_FORM",
								mode: "EDIT",
								dataIds: dataIds,
							})
						);
					},
				},
				{
					key: "STATUS_SELECT",
					style: { align: "RIGHT", notCollapsible: true, componentAlone: true },
					hideOnDesktop:
						((!hasEditTripsPermission || !STATUS_TRIPS.pending.includes(trip.status)) &&
							!STATUS_TRIPS.active.includes(trip.status)) ||
						!isLogged,
					hideOnMobile:
						((!hasEditTripsPermission || !STATUS_TRIPS.pending.includes(trip.status)) &&
							!STATUS_TRIPS.active.includes(trip.status)) ||
						!isLogged,
					component: <TripStatusSelector trip={trip} userId={dataIds?.userId} carrierId={carrierId} />,
				},
				{
					key: "MANAGE_INVOICES",
					title: "Manage Invoice",
					style: { align: "LEFT", icon: "receipt" },
					hideOnDesktop: !isTripInvoicingEligible || !invoiceHasAccessEdit || !isLogged,
					hideOnMobile: nativeMobile || !isTripInvoicingEligible || !invoiceHasAccessEdit || !isLogged,
					onClick: () => {
						if (basicRestrictions) {
							setPaywallDialog({
								open: true,
								dataIds: {
									type: "default",
									data: { dataIdsInherited: dataIds, callbackKey: "TRIP_INVOICE_VIEW" },
								},
							});
							return;
						}

						dispatch(
							openLoadedFormDialog({
								viewId: "TRIP_INVOICE_VIEW",
								dataIds: dataIds,
							})
						);
					},
				},
				{
					key: "SEND_BOL",
					title: "Send Files",
					style: { align: "LEFT", icon: "send" },
					hideOnDesktop: !isManager || trip?.pod_files?.length < 1 || !isLogged,
					hideOnMobile: !isManager || trip?.pod_files?.length < 1 || !isLogged,
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								viewId: "FILE_SHARING_DIALOG",
								dataIds: { carrierId, tripId: trip._id, from: "TRIP" },
							})
						);
					},
				},
			]}
		>
			<TripCreateTranscription
				data={tripUpdated}
				dialogConfig={dialogTranscription}
				onChange={(event, value) => {
					setTripUpdated({ ...tripUpdated, transcription_verified__flag: value });
				}}
				onAccept={() => {
					if (!tripUpdated) {
						handleEditTranscription();
					} else if (tripUpdated?.transcription_verified__flag) {
						handleSaveTrip();
					} else {
						setDialogAlert({
							flag: true,
							message: "Please check transcription verified checkbox to continue.",
							type: "transcription-alert",
							optionsText: { accept: "OK" },
						});
					}
				}}
				onEdit={() => handleEditTranscription()}
				onClose={onCloseDialogTranscription}
			/>
			<WarningConfirmDialog
				customMessage={openWarningDialog?.title}
				open={!!openWarningDialog}
				warnings={openWarningDialog?.warnings}
				onClose={() => {
					ignoreWarnings.current = false;
					setOpenWarningDialog(null);
				}}
				onAccept={() => {
					ignoreWarnings.current = true;
					setOpenWarningDialog(null);
					onClickNextState();
				}}
			/>
			<SmarthopConfirmDialog
				open={!!openDialogAlert.flag}
				title={openDialogAlert?.message}
				onAccept={() => setDialogAlert({ flag: false, message: null, type: null, optionsText: {} })}
				acceptMsg={openDialogAlert?.optionsText?.accept}
			/>

			<PaywallDialog data={paywallDialog} onHandlerPaywall={() => setPaywallDialog({})} />
		</SmarthopDialogViewContainer>
	);
});

export default TripView;
