import { useState, useEffect, useMemo, forwardRef, useRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import uniqid from "uniqid";
import _ from "@lodash";
import moment from "moment";

// UI Library
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

// Utils
import { showErrorSnackbar, showSnackbar } from "app/main/utils/snackbarUtil";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { createLocationView, getLocationsUpdate } from "app/main/utils/tripsUtils";
import { openFormDialog, updateTopmostDialog } from "app/store/tools/formDialogSlice";
import { addDummyFile, deleteOneFile } from "app/store/upload/filesSlice";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import WarningConfirmDialog from "../../common/WarningConfirmDialog";
import { createContent } from "@smarthop/form/configs/tripDetailsContent";
import ParsingPopup from "./ParsingPopup";

// Services
import { getTrip, getTripLocationsMiles, updateTrip, createTrip } from "app/services/tripsServices";
import { getCarrierConfig } from "app/services/carrierServices";
import { updateCurrentDriverTruck } from "app/services/truckServices";
import { getParsedRC } from "app/services/multiUploadServices";
import { saveBrokerTripCarrier } from "app/services/brokersServices";
import { getCarrierUsers } from "app/services/usersServices";
import { isTestCarrier, getUserTier } from "app/services/LoginService";

// Public Services
import {
	getTripPublic,
	getTripLocationsMilesPublic,
	getCarrierConfigPublic,
	getCarrierUsersPublic,
	updateCurrentDriverTruckPublic,
	updateTripPublic,
	saveBrokerTripCarrierPublic,
} from "app/services/publicServices";

// Utils
import { hasRequiredGateKeepers, roleRestrictions, processTierRestrictions } from "app/main/utils/rolesUtils";

// Gatekeeper
import { isEnabled } from "app/services/featureStorageService";

// Const
const useStyles = makeStyles(() => {
	return {
		content: {
			height: "calc(100vh - 200px) !important",
			overflowY: "hidden",
		},
	};
});

const TYPE_FILE_ENABLED = [
	"pod",
	"rate_confirmation",
	"proof_of_delivery",
	"packing_list",
	"lumper_receipt",
	"dock/warehouse_receipt",
	"scale_or_weight_receipt",
	"cargo_picture",
];

const _MESSAGE_CHANGE_DRIVER = (name, isPrimary, isRemoved) => {
	return `Is ${name} ${isRemoved ? "not a" : ""} current active ${isPrimary ? "primary driver" : "secondary driver"}`;
};
const _TITLE_UPDATED_TRAILER = (trailerid, truckId) => {
	if (trailerid) {
		return `Do you want to assign the trailer '${trailerid}' to the truck '${truckId}'?`;
	} else {
		return `Do you want to remove the trailer from the truck '${truckId}'?`;
	}
};
const _TITLE_SEND_DRIVER_NOTIFICATION = (name, phone) => {
	return `Would you like to notify driver ${name} (${phone}) to upload BOL documents for this trip?`;
};
const _MESSAGE_UPDATED_TRAILER = `Currently the trailer is assigned. If you accept, this trailer will be transferred to this truck.`;

const _KEYS_AFFECT_SAVE_BROKERS = [
	"mcnumber",
	"broker_contact",
	"phone_broker",
	"mail_broker",
	"customerId",
	"broker",
	"extraContacts",
];

const _BROKER_DATA_CLEAN = {
	broker: null,
	broker__view: {},
	mcnumber: null,
	customerId: null,
	broker_contact: null,
	phone_broker: null,
	mail_broker: null,
};

const _TRUCK_DRIVER_DATA_CLEAN = {
	truck: null,
	truck__view: {},
	truck_trailer: null,
	truck_trailer__view: {},
	driver: null,
	driver__view: {},
	driver_secondary: null,
	driver_secondary__view: {},
	investor: null,
	investor__view: {},
	current_location: null,
	current_location__view: {},
	zip_code_current_location: null,
};

const _CREATE_COMPARISON_VIEW = (data) => {
	return (
		<div className="flex flex-col min-w-230 pt-12 pl-6">
			{data.map((row, indexR) => {
				return (
					<>
						<div key={"row_" + indexR} className={"flex flex-row h-24 items-center "}>
							{row.map((cell, indexC) => {
								return (
									<>
										<div
											key={"col_" + indexR}
											className={"flex items-center flex-1 " + (indexC === 0 ? " pr-2 " : " justify-end  ")}
										>
											<Typography
												component={"div"}
												className={
													indexC === 0 || indexR === 0
														? "text-11 uppercase font-bold tracking-wide text-white opacity-80"
														: indexC === 1
														? "text-12 tracking-wide"
														: "text-12 font-semibold tracking-wide"
												}
											>
												{cell ?? "None"}
											</Typography>
										</div>
										{indexC === 0 ? (
											<div key={"sep_" + indexR} className="flex w-1 h-14 bg-white ml-4 opacity-30" />
										) : indexC === 1 ? (
											<div key={"arrow_" + indexR} className="flex ml-14 -mr-6 items-center justify-center">
												<Icon className="text-14 text-white opacity-60">east</Icon>
											</div>
										) : null}
									</>
								);
							})}
						</div>
						{indexR !== data.length - 1 && <div className="flex w-full h-1 bg-white opacity-30 " />}
					</>
				);
			})}
		</div>
	);
};

// WORAROUND TO UPDATE RPM when rate changed
let rateUpdateTimeout = null;

const TripEditView = forwardRef((props, ref) => {
	useEffect(() => {
		props?.setTitle?.(
			props?.dataIds?.mode === "CREATE"
				? "Create Trip"
				: props?.dataIds?.historyData
				? "View Trip Details"
				: "Edit Trip Details"
		);
		// eslint-disable-next-line
	}, [props?.dataIds]);

	const classes = useStyles();
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const onLoading = props.onLoading;
	const onProgress = props.onProgress;
	const nativeMobile = props.nativeMobile;
	const onDone = props.onDone;
	const updateDialog = props.updateDialog;
	const dataIds = { ...(props.dataIds ?? {}), exclude_trip_id: props.dataIds?.tripId };
	const tripId = dataIds?.tripId === "null" ? null : dataIds?.tripId;
	const mode = dataIds?.mode;

	const user = useSelector(({ auth }) => auth.user);
	const role = user.role;
	const roleType = user.roleType;
	const userTier = getUserTier();

	const hasRatesOnPermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const [revision] = useState(0);
	const [smallScreen] = useState(window.innerWidth < 1200);
	const [processing, setProcessing] = useState(false);
	const [data, setData] = useState({ original: null, edited: null });
	const carrierId = dataIds?.carrierId ?? data?.edited?.carrier;
	const [parsedMetadata, setParsedMetadata] = useState(null);
	const [error, setError] = useState(null);
	const [changeStatusDelivered, setChangeStatusDelivered] = useState(false);
	const [changeTrailer, setChangeTrailer] = useState(false);
	const [changeDriverTruck, setChangeDriverTruck] = useState(false);
	const [changeSecondaryDriverTruck, setChangeSecondaryDriverTruck] = useState(false);
	const [changeBrokerKeys, setChangeBroker] = useState([]);
	const [paywallDialog, setPaywallDialog] = useState({});
	const [dialogConfirm, setDialogConfirm] = useState({ flag: false, message: null, type: null });
	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
	const ignoreWarnings = useRef(false);
	const ignoreWarningsList = useRef([]);
	const [tripInvoiceWarnings, setTripInvoiceWarnings] = useState();
	const [hasRequired, setHasRequired] = useState(true);

	const reactNative = window?.ReactNativeWebView ?? false;

	const dialogStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const currentDialog = dialogStack[dialogStack.length - 1];
	const dialogId = currentDialog?.dialogId;

	const fileUploadId = useRef(dataIds.uploadId ?? currentDialog?.metadata?.rcUpload?.uploadId ?? uniqid());
	const parsingPopupRef = useRef();
	const revisionActionsRef = useRef({});
	const showButtonSaveBroker = changeBrokerKeys?.length && roleType === "EXTERNAL";
	const assignedTruckId = data.edited ? data.edited?.truck : data?.original?.truck;

	let uploadedFile = useSelector(({ upload }) => upload?.files?.list ?? []);
	uploadedFile = useMemo(
		() => uploadedFile?.find((file) => file?.metadata?.uploadId === fileUploadId.current),
		[uploadedFile]
	);

	const isPublicView = useMemo(() => !!dataIds?.public, [dataIds.public]);
	const canDisableRequired = useMemo(() => isTestCarrier(), []);
	useImperativeHandle(
		ref,
		() => {
			if (!canDisableRequired) return;
			return { required: { onClick: () => setHasRequired(false), title: "Disable Validation", icon: "report_off" } };
		},
		[canDisableRequired]
	);

	const onFileUpload = (file) => {
		dispatch(
			addDummyFile({
				status: "uploading",
				metadata: { name: file.name, type: "RC", uploadId: fileUploadId.current },
				dialogId,
			})
		);
		dispatch(
			updateTopmostDialog({
				metadata: { rcUpload: { uploadId: fileUploadId.current, tripId, isApplied: false } },
			})
		);
		if (smallScreen) {
			setTimeout(() => {
				parsingPopupRef.current?.scrollIntoView();
			}, 1000);
		}
	};

	const openPaywallView = (type) => {
		setPaywallDialog({
			open: true,
			dataIds: {
				type:
					type === "SEND_DRIVER_NOTIFICATION"
						? "driver_notification_restriction"
						: "automate_functionality_restriction",
				data: {
					dataIdsInherited: dataIds,
					callback: type === "SEND_DRIVER_NOTIFICATION" ? handledSave : null,
					callbackKey: type === "SEND_DRIVER_NOTIFICATION" ? null : "TRIP_EDIT_VIEW",
				},
			},
		});
	};

	const { isApplied } = dataIds?.metadata?.rcUpload ?? currentDialog?.metadata?.rcUpload ?? {};

	const handleApplyParsing = async () => {
		try {
			const { parsedData } = await getParsedRC(uploadedFile?.fileId);
			setData((data) => ({
				...data,
				original: {
					...(data?.original ?? {}),
					...parsedData,
					rate_con_file: [uploadedFile?.fileId],
					rate_con_file__flag: true,
				},
			}));
			dispatch(deleteOneFile({ uploadId: uploadedFile?.metadata?.uploadId, fileId: uploadedFile?.fileId, carrierId }));
		} catch (err) {
			console.error(err);
		} finally {
			setProcessing(false);
			onProgress?.(false);
		}
	};

	const handleUploadDocument = async () => {
		try {
			const openAiDoc = uploadedFile?.metadata?.openAiDoc;

			let documentTypeParsed = openAiDoc?.metadata?.[0]?.document_type?.toLowerCase()?.replaceAll(" ", "_");
			documentTypeParsed = documentTypeParsed === "bill_of_lading" ? "pod" : documentTypeParsed;

			const documentType =
				openAiDoc?.metadata?.length > 1 ||
				documentTypeParsed === "other" ||
				!TYPE_FILE_ENABLED.includes(documentTypeParsed)
					? "other"
					: documentTypeParsed;

			const podData = data.edited.pod_files ?? data.original.pod_files ?? [];
			podData.push({
				file_id: [uploadedFile.fileId],
				file_id__flag: true,
				description: uploadedFile.metadata.name,
				type: documentType,
				analize: { status: "DONE", metadata: openAiDoc },
			});
			setData((data) => ({
				original: {
					...(data?.original ?? {}),
					rate_con_file: [],
					rate_con_file__flag: false,
					pod_files: podData,
				},
				edited: {
					...(data?.edited ?? {}),
					rate_con_file: [],
					rate_con_file__flag: false,
					pod_files: podData,
				},
			}));
			dispatch(deleteOneFile({ uploadId: uploadedFile?.metadata?.uploadId, fileId: uploadedFile?.fileId, carrierId }));
		} catch (err) {
			console.error(err);
		} finally {
			setProcessing(false);
			onProgress?.(false);
		}
	};

	const saveBroker = () => {
		const { mcnumber, mail_broker, broker_contact, customerId, broker__view, phone_broker, extraContacts } =
			data?.edited;
		const dataSend = {
			mcnumber: mcnumber,
			email: mail_broker,
			contact_name: broker_contact,
			contact: phone_broker,
			customerId: customerId,
			carrier: carrierId,
			clientid: broker__view?.label ?? data.original?.broker__view?.label,
			extraContacts,
			tripId,
		};

		const serviceFunction = isPublicView ? saveBrokerTripCarrierPublic : saveBrokerTripCarrier;
		serviceFunction({ brokerData: dataSend, tripData: { ...data.original, ...data?.edited } }, dataIds.userId).then(
			(broker) => {
				setData({
					original: { ...data?.original, ...data?.edited, broker__view: broker },
					edited: { ...data?.edited, broker__view: broker },
				});
				showSnackbar(snackbar, "Broker succesfully save/update");
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				setChangeBroker([]);
			},
			(err) => {
				const message = err?.errors?.[0]?.message ?? "Fail to save/update broker";
				showSnackbar(snackbar, message, "error");
			}
		);
	};

	const handleStatus = (model) => {
		let updatedData = { ...model } ?? {};
		const status = model.status;
		const locations = data?.edited?.locations ?? data?.original?.locations;
		if (locations?.length > 0) {
			updatedData.locations = getLocationsUpdate(
				locations,
				model.status__view.description,
				status?.split("-")?.[0],
				data?.original?.delivery_address
			);
		}
		if (status === "Delivered") {
			setChangeStatusDelivered(true);
			updatedData.marked_delivered_date = moment();
		}
		setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
	};

	const showRCPreview = !smallScreen && !reactNative && hasRatesOnPermission;
	const content = useMemo(
		() =>
			createContent({
				mode,
				role,
				roleType,
				userTier,
				data,
				parsedMetadata,
				showRCPreview,
				isNative: !!reactNative,
				onFileUpload,
				openPaywallView,
				extraFormData: {
					rate_con_file: { uploadIds: [fileUploadId.current], isPriority: true, carrierId, ignoreCreateTrip: true },
				},
				saveBroker,
				showButtonSaveBroker,
				brokerMetadata: data?.original?.broker__view?.metadata ?? data?.broker__view?.metadata,
				isTripSmartPayEligible: data?.original?.smartpay_eligible,
				tripId,
				assignedTruckId,
				smartPayModuleEnabled:
					data?.original?.carrierMetadata?.smartPayModuleEnabled ??
					data?.original?.carrier__view?.metadata?.hasSmartPayProgram,
				payrollModuleEnabled: data?.original?.carrierMetadata?.payrollModuleEnabled,
				invoicingModuleEnabled: data?.original?.carrierMetadata?.invoiceModuleEnabled,
				billingModuleEnabled: data?.original?.carrierMetadata?.billingModuleEnabled,
				hasSubaccounts,
				hasRatesOnPermission,
				hasRequired,
				isPublicView,
				disableBrokerUpdates: !processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier }),
			}),
		//eslint-disable-next-line
		[
			parsedMetadata,
			mode,
			role,
			roleType,
			userTier,
			data,
			smallScreen,
			showButtonSaveBroker,
			hasSubaccounts,
			assignedTruckId,
			hasRequired,
			isPublicView,
		]
	);

	const handlerRCData = (rcData, tripInfo) => {
		rcData = {
			...rcData,
			...tripInfo,
			rate_con_file: rcData.rate_con_file,
			rate_con_file__flag: rcData.rate_con_file__flag,
		};

		if (reactNative) dataIds.rcDataEditable = rcData;
		if (dataIds?.rcDataParsed) {
			setParsedMetadata({ status: "AUTO_PARSING_SUCCESS" });
		}

		const originalData = { ...rcData };

		if (!hasSubaccounts) {
			const enterpriseFeatures = user.enterpriseFeatures;
			// For Carriers without subaccounts we set default trip configuration
			originalData.carrierMetadata = {};
			// These fields determine if the checkboxes are rendered or not
			originalData.carrierMetadata.payrollModuleEnabled = enterpriseFeatures?.payroll_module_flag;
			originalData.carrierMetadata.invoiceModuleEnabled = enterpriseFeatures?.invoicingModuleOnboarding === "COMPLETE";
			// These fields determine the value of the checkboxes
			originalData.payroll_elegible = !!originalData.carrierMetadata.payrollModuleEnabled;
			originalData.invoicing_eligible = !!originalData.carrierMetadata.invoiceModuleEnabled;
			originalData.enterprise_features = enterpriseFeatures;
		}

		setData({ original: originalData });
		setError(null);
		setProcessing(false);
		if (onProgress) onProgress(false);
		onLoading?.(false);
	};

	useEffect(() => {
		if (dataIds?.historyData) {
			setData({ original: dataIds?.historyData });
			setError(null);
			setProcessing(false);
			onLoading?.(false);
			onProgress?.(false);
			return;
		}

		if (!data.original) onLoading?.(true);

		if (dataIds?.rcDataEditable) {
			let rcData = dataIds?.rcDataEditable;

			async function fetchTripData() {
				if (reactNative) {
					rcData = JSON.parse(rcData);

					if (dataIds.tripId && dataIds.tripId !== "null") {
						getTrip(dataIds.tripId, dataIds.carrierId).then(
							(trip) => handlerRCData(rcData, trip),
							(err) => console.log(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...")
						);
					} else {
						handlerRCData(rcData, {});
					}
				} else {
					handlerRCData(rcData, {});
				}
			}

			fetchTripData();
			return;
		}

		if (!tripId || !carrierId) {
			setError("Invalid input");
			return;
		}

		async function fetchInfoData() {
			const getTripService = isPublicView ? getTripPublic : getTrip;
			getTripService(tripId, carrierId).then(
				(trip) => {
					if (trip.status === "Missing Data") {
						trip.status = null;
						trip.status__view = {};
					}
					if (trip.load_id.includes("TEMP-")) {
						trip.load_id = null;
					}
					if (dataIds.continueAutoTranscribe) {
						trip = { ...trip, ...dataIds };
					}
					setData({ ...data, original: trip });
					setError(null);
					setProcessing(false);
					onLoading?.(false);
				},
				(err) => {
					setError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
					onLoading?.(false);
				}
			);
		}

		fetchInfoData();
		// eslint-disable-next-line
	}, [revision]);

	const onSubmit = () => {
		const actions = revisionActionsRef.current;

		if ((changeDriverTruck || changeSecondaryDriverTruck) && !actions.hasOwnProperty("CHANGE_DRIVER")) {
			const driverId = data?.edited?.driver;
			const secondaryDriverId = data?.edited?.driver_secondary;
			if (!driverId && secondaryDriverId) {
				showSnackbar(
					snackbar,
					"You cannot have a truck with a secondary driver that does not have a primary driver",
					"error"
				);
				return;
			}
			if (driverId && secondaryDriverId && driverId === secondaryDriverId) {
				showSnackbar(snackbar, "Primary Driver and Secondary Driver cannot be the same", "error");
				return;
			}

			const { equipment, truckid } = data?.edited?.truck__view?.metadata?.truck ?? {};
			const primaryDriver = data?.edited?.driver__view?.label;
			const primaryDriverOriginal = data?.original?.driver__view?.label;
			const secondaryDriver = data?.edited?.driver_secondary__view?.label;
			const secondaryDriverOriginal = data?.original?.driver_secondary__view?.label;

			// Primary
			let primaryMessage = "";
			if (changeDriverTruck && primaryDriver) {
				primaryMessage = _MESSAGE_CHANGE_DRIVER(primaryDriver, true, false);
			} else if (changeDriverTruck && !primaryDriver) {
				primaryMessage = _MESSAGE_CHANGE_DRIVER(primaryDriverOriginal, true, true);
			}

			// Secondary
			let secondaryMessage = "";
			if (changeSecondaryDriverTruck && secondaryDriver) {
				secondaryMessage = _MESSAGE_CHANGE_DRIVER(secondaryDriver, false, false);
			} else if (changeSecondaryDriverTruck && !secondaryDriver) {
				secondaryMessage = _MESSAGE_CHANGE_DRIVER(secondaryDriverOriginal, false, true);
			}

			const message = `${primaryMessage} ${
				primaryMessage && secondaryMessage ? "&" : ""
			} ${secondaryMessage} for ${equipment} #${truckid}?`;
			return setDialogConfirm({ flag: true, title: message, type: "CHANGE_DRIVER" });
		}

		if (changeTrailer && !actions.hasOwnProperty("CHANGE_TRAILER")) {
			const auxData = data.edited ?? data.original;
			const trailer = auxData.truck_trailer__view?.metadata;
			const truck = auxData.truck__view?.metadata?.truck;
			return setDialogConfirm({
				flag: true,
				title: _TITLE_UPDATED_TRAILER(trailer?.trailerid, truck?.truckid),
				message: trailer?.assigned ? _MESSAGE_UPDATED_TRAILER : null,
				type: "CHANGE_TRAILER",
			});
		}

		const auxData = data.edited ?? data.original;
		const driver = auxData.driver__view;
		if (
			changeStatusDelivered &&
			driver?.label &&
			driver?.metadata?.phone &&
			!actions.hasOwnProperty("SEND_DRIVER_NOTIFICATION") &&
			isEnabled("SEND_DRIVER_BOL_NOTIFICATION")
		) {
			return setDialogConfirm({
				flag: true,
				title: _TITLE_SEND_DRIVER_NOTIFICATION(driver.label, driver.metadata.phone),
				type: "SEND_DRIVER_NOTIFICATION",
				acceptMsg: "Send Notification",
			});
		}

		if (
			revisionActionsRef.current["SEND_DRIVER_NOTIFICATION"] &&
			!processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier })
		) {
			return openPaywallView("SEND_DRIVER_NOTIFICATION");
		}

		handledSave();
	};

	const handledSave = async () => {
		if (processing) {
			console.log("Action in progress...");
			return;
		}

		if (revisionActionsRef.current["CHANGE_DRIVER"]) {
			let dataSend = {
				driver__view: data?.edited?.driver__view,
				driver: data?.edited?.driver,
				driver_secondary: data?.edited?.driver_secondary,
			};
			const serviceFunction = isPublicView ? updateCurrentDriverTruckPublic : updateCurrentDriverTruck;
			await serviceFunction(carrierId, data?.edited.truck, dataSend, dataIds.userId).then(
				(response) => {
					showSnackbar(snackbar, "Current Driver updated successfully", "success");
				},
				(err) => {
					const message = err?.errors?.[0]?.message ?? "Fail updating the current driver";
					showSnackbar(snackbar, message, "error");
				}
			);
		}

		setDialogConfirm({ flag: false, message: null, type: null });

		onProgress?.(true);
		setProcessing(true);

		console.log("[TripEditView] Saving trip...");

		const postData = data.edited ?? data.original;
		postData.load_id = postData.load_id.trim();
		postData.actions = revisionActionsRef.current;
		if (!postData.broker) postData.broker = data.edited?.broker ?? data.original?.broker;
		postData.broker_id = postData.broker;

		// trip invoice warning flag
		postData.ignoreWarnings = ignoreWarnings.current;
		postData.ignoreWarningsList = ignoreWarningsList.current;

		// workaround for rate on permission
		if (!hasRatesOnPermission) {
			const dOriginal = data.original;
			if (!postData.rate && dOriginal.rate_original) postData.rate = dOriginal.rate_original;
			if (!postData.profit && dOriginal.profit_original) postData.profit = dOriginal.profit_original;
			if (!postData.rpm && dOriginal.rpm_original) postData.rpm = dOriginal.rpm_original;
		}

		const serviceUpdateFunction = isPublicView ? updateTripPublic : updateTrip;
		const params = isPublicView ? { userId: dataIds.userId, emailAlias: dataIds.emailAlias } : {};
		const promise =
			dataIds?.mode === "CREATE"
				? createTrip({ ...dataIds?.rcDataEditable, ...postData })
				: serviceUpdateFunction(tripId, carrierId, postData, params);

		promise.then(
			(res) => {
				let message = "Trip Details Updated";
				if (dataIds.mode === "CREATE") message = "Trip Created";
				if (revisionActionsRef.current["SEND_DRIVER_NOTIFICATION"]) message += " and Send Driver Notification.";
				showSnackbar(snackbar, message, "success");

				setError(null);
				setProcessing(false);
				ignoreWarnings.current = false;
				ignoreWarningsList.current = [];
				setIsWarningDialogOpen(false);
				onProgress?.(false);
				onDone?.(true);

				// Trigger refresh of visible tables
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "dashboardRevision" }));
				if (roleType === "EXTERNAL") dispatch(fetchActions({ carrierId }));

				if (dataIds.mode === "CREATE") {
					if (reactNative) {
						window?.ReactNativeWebView?.postMessage(
							JSON.stringify({ type: "TRIP_VIEW", data: { tripId: res._id, carrierId } })
						);
						return;
					}
					dispatch(openFormDialog({ viewId: "TRIP_VIEW", dataIds: { carrierId, tripId: res._id } }));
				} else {
					if (reactNative) {
						window?.ReactNativeWebView?.postMessage(
							JSON.stringify({
								type: "REFRESH_TRIP_LIST",
								data: { carrierId, moveToTripsTab: !!dataIds?.rcDataEditable },
							})
						);
					}
				}
			},
			(err) => {
				const tripWarnings = err?.errors?.[0]?.metadata?.warnings;
				setTripInvoiceWarnings(tripWarnings);
				if (!tripWarnings?.length) {
					showErrorSnackbar(snackbar, err, { duration: 5000 });
				} else {
					setIsWarningDialogOpen(true);
				}
				setProcessing(false);
				onProgress?.(false);
			}
		);
	};

	const notChangeKey = (dataOriginal, dataCurrent) => {
		if (Array.isArray(dataCurrent)) {
			if (_.isEqual(dataOriginal, dataCurrent)) {
				return true;
			}
			return false;
		} else {
			return dataOriginal === dataCurrent;
		}
	};

	const onAcceptWarningChanges = () => {
		cleanTripInvoiceWarnings();
		ignoreWarnings.current = true;
		ignoreWarningsList.current = tripInvoiceWarnings;
		onSubmit();
	};

	const cleanTripInvoiceWarnings = () => {
		setTripInvoiceWarnings();
	};

	const handledRateUpdated = (model) => {
		clearTimeout(rateUpdateTimeout);
		if (model.miles > 0 && !model.tonu) {
			rateUpdateTimeout = setTimeout(() => {
				let updatedData = { ...model } ?? {};
				updatedData.rpm = Number(
					(Number(model.rate) / (Number(model.miles) + Number(model.emptymiles ?? 0))).toFixed(2)
				);

				if (Number(model.rpm) !== Number(updatedData.rpm)) {
					showSnackbar(
						snackbar,
						_CREATE_COMPARISON_VIEW([
							["Updated", "BEFORE", "AFTER"],
							["RPM", "$" + (model.rpm ?? 0), "$" + updatedData.rpm],
						]),
						"info",
						{ duration: 6000 }
					);
				}

				setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
			}, 1000);
		}
	};

	const handledCarrierUpdated = async (model) => {
		let updatedData = {
			...model,
			carrierMetadata: {},
			enterprise_features: {},
			..._BROKER_DATA_CLEAN,
			..._TRUCK_DRIVER_DATA_CLEAN,
		};

		setProcessing(true);
		const carrierId = model.carrier;
		if (carrierId) {
			const [carrierConfig, carrierUsers] = await Promise.all([
				isPublicView ? getCarrierConfigPublic(carrierId, dataIds.userId) : getCarrierConfig(carrierId),
				isPublicView ? getCarrierUsersPublic(carrierId, dataIds.userId) : getCarrierUsers(carrierId),
			]);

			// Get previous selected booker
			const previousBookerEmail = data?.edited?.creator__view?.description ?? user.email;
			const emailUser = previousBookerEmail?.split("@")[0];
			const booker = carrierUsers.items?.find((user) => user?.email?.includes(emailUser));
			if (booker) {
				updatedData.creator = booker._id;
				updatedData.creator__view = {
					value: booker?._id,
					label: booker?.first_name + " " + booker?.last_name,
					description: booker?.email,
				};
			}

			// Allows showing Payroll and Invoicing checkboxes dynamically
			updatedData.carrierMetadata.smartPayModuleEnabled = carrierConfig.hasSmartPayProgram;
			updatedData.carrierMetadata.payrollModuleEnabled = carrierConfig.enterprise_features?.payroll_module_flag;
			updatedData.carrierMetadata.invoiceModuleEnabled =
				carrierConfig.enterprise_features?.invoicingModuleOnboarding === "COMPLETE";
			// Auto check boxes
			updatedData.payroll_elegible = !!updatedData.carrierMetadata.payrollModuleEnabled;
			updatedData.invoicing_eligible = !!updatedData.carrierMetadata.invoiceModuleEnabled;
			updatedData.enterprise_features = carrierConfig.enterprise_features;

			if (mode === "CREATE") {
				updatedData.send_broker_dispatched = carrierConfig?.notificationSettings?.dispatchedNotificationBroker;
				updatedData.send_broker_at_pick_up = carrierConfig?.notificationSettings?.atPickedUpNotificationBroker;
				updatedData.send_broker_at_delivery = carrierConfig?.notificationSettings?.atDeliveryNotificationBroker;
			}
		}

		setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
		setProcessing(false);
	};

	const handledTruckUpdated = (model) => {
		let updatedData = { ...model } ?? {};

		const { current_location } = model?.truck__view?.metadata ?? {};
		const current_location_view = createLocationView(current_location);
		updatedData.current_location = current_location?.toUpperCase() ?? null;
		updatedData.current_location__view = current_location_view ?? null;

		let driver__view = model.truck__view?.metadata?.driver__view;
		updatedData.driver = driver__view?.value ?? null;
		updatedData.driver__view = driver__view?.value ? driver__view : { value: undefined, label: undefined };
		let driver_secondary__view = model.truck__view?.metadata?.driver_secondary__view;
		updatedData.driver_secondary = driver_secondary__view?.value ?? null;
		updatedData.driver_secondary__view = driver_secondary__view?.value
			? driver_secondary__view
			: { value: undefined, label: undefined };

		let truck_trailer__view = model.truck__view?.metadata?.truck_trailer__view;
		updatedData.truck_trailer = truck_trailer__view?.value ?? null;
		updatedData.truck_trailer__view = truck_trailer__view?.value
			? truck_trailer__view
			: { value: undefined, label: undefined };

		let investor__view = model.truck__view?.metadata?.investor__view;
		updatedData.investor = investor__view?.value ?? null;
		updatedData.investor__view = investor__view?.value ? investor__view : { value: undefined, label: undefined };

		if (model?.truck__view?.metadata?.equipment && !updatedData.equipment) {
			updatedData.equipment = model?.truck__view?.metadata?.equipment;
		}

		setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
		setChangeTrailer(false);
		handledLocationsUpdated(updatedData);
	};

	const handledLocationsUpdated = (model, changedAddress, resetObject) => {
		if (processing) {
			console.log("Action in progress...");
			return;
		}

		const locations = {
			empty: model.current_location?.length > 0 ? model.current_location : null,
			pickup: model.pickup_address?.length > 0 ? model.pickup_address : null,
			stops: model.locations.map((item) => item.location ?? null),
			delivery: model.delivery_address?.length > 0 ? model.delivery_address : null,
		};

		if (!locations.empty || !locations.pickup || locations.stops.includes(null) || !locations.delivery) {
			console.log("[TripEditView] Trip locations changed, but some fields are empty, can't recalculate...");
			if (resetObject) {
				// If RC uploaded but some data is still missing we anyway show save data
				setData({ original: { ...data?.original, ...model }, edited: model });
			}
			return;
		}

		onProgress?.(true);
		setProcessing(true);

		console.log(`[TripEditView] Trip locations changed (new address ${changedAddress}), need to recalculate miles...`);
		const serviceFunction = isPublicView ? getTripLocationsMilesPublic : getTripLocationsMiles;
		serviceFunction(
			locations,
			changedAddress,
			data.original.truck,
			data.original.carrier,
			model.weight,
			tripId,
			dataIds.userId
		).then(
			(calulatedMiles) => {
				let updatedData = { ...model } ?? {};
				updatedData.emptymiles = calulatedMiles.empty;
				updatedData.miles = updatedData.tonu ? 1 : calulatedMiles.loaded;
				updatedData.rpm = updatedData.tonu
					? 0
					: (Number(updatedData.rate) / (Number(updatedData.miles) + Number(updatedData.emptymiles ?? 0))).toFixed(2);

				if (changedAddress) {
					const newZip = calulatedMiles.changedAddressZip ?? "";
					if (updatedData.current_location === changedAddress) {
						updatedData.zip_code_current_location = newZip;
					}
					if (updatedData.pickup_address === changedAddress) {
						updatedData.zip_code_pickup_address = newZip;
					}
					if (updatedData.delivery_address === changedAddress) {
						updatedData.zip_code_delivery_address = newZip;
					}
					updatedData.locations?.forEach((item) => {
						if (item.location === changedAddress) {
							item.zip_code_location = newZip;
						}
					});
					if (!newZip?.length) {
						showSnackbar(snackbar, `Zip code not found fot the entered address ${changedAddress}`, "warning", {
							duration: 5000,
						});
					}
				}

				setProcessing(false);
				onProgress?.(false);

				setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });

				if (
					Number(model.rpm) !== Number(updatedData.rpm) ||
					Number(model.emptymiles) !== Number(updatedData.emptymiles) ||
					Number(model.miles) !== Number(updatedData.miles)
				) {
					showSnackbar(
						snackbar,
						_CREATE_COMPARISON_VIEW([
							["Updated", "BEFORE", "AFTER"],
							["Empty", (model.emptymiles ?? 0) + " mi", updatedData.emptymiles + " mi"],
							["Loaded", (model.miles ?? 0) + " mi", updatedData.miles + " mi"],
							["RPM", "$" + (model.rpm ?? 0), "$" + updatedData.rpm],
						]),
						"info",
						{ duration: 6000 }
					);
				}
			},
			(err) => {
				setProcessing(false);
				onProgress?.(false);

				const message = err?.errors?.[0]?.message ?? err.message ?? "Failed to racalcualte miles...";
				showSnackbar(snackbar, message, "error");
			}
		);
	};

	const handledRCUpdated = (model) => {
		// handle case when the RC is deleted and re-uploaded
		if (uploadedFile?.fileId && uploadedFile?.fileId !== model?.rate_con_file?.[0]) {
			const newUploadId = uniqid();
			fileUploadId.current = newUploadId;
		}

		if (!model.rate_con_file[0]) {
			console.log("Not have rate con file");
			if (uploadedFile) {
				dispatch(
					deleteOneFile({ uploadId: uploadedFile?.metadata?.uploadId, fileId: uploadedFile?.fileId, carrierId })
				);
			}
			onProgress?.(false);
			updateDialog?.({ dialogId, updateData: { titleOverrides: null } });
		}
	};

	const handledSuggestedAddressUpdated = (model) => {
		let updatedData = { ...model } ?? {};
		let address = updatedData.suggested_empty_address;
		updatedData.current_location = address;
		updatedData.current_location__view = { value: address, label: address };
		delete updatedData.suggested_empty_address;
		delete updatedData.suggested_empty_address__view;
		handledLocationsUpdated(updatedData, updatedData.current_location, true);
	};

	const handledTonuUpdated = (model) => {
		if (model.tonu) {
			rateUpdateTimeout = setTimeout(() => {
				let updatedData = { ...model } ?? {};
				updatedData.miles = 1;
				updatedData.rpm = 0;
				setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
			}, 1000);
		} else {
			handledLocationsUpdated(model, null, true);
		}
	};

	const handleBrokerUpdate = (model) => {
		let updatedData = { ...model } ?? {};
		updatedData.broker_contact = model.broker__view?.metadata?.broker_contact;
		updatedData.mcnumber = model.broker__view?.metadata?.mcnumber;
		updatedData.mail_broker = model.broker__view?.metadata?.mail_broker;
		updatedData.phone_broker = model.broker__view?.metadata?.phone_broker;
		updatedData.customerId = model.broker__view?.metadata?.customerId;
		updatedData.extraContacts = model.broker__view?.metadata?.extraContacts;
		if (model.broker__view?.metadata) {
			updatedData.broker__view.metadata.smartpayEnabled = model.broker__view?.metadata?.smartpayEnabled;
			if (model.broker__view?.metadata.carrier) {
				updatedData.broker__view.badge = { label: "Saved", type: "info" };
			}
		}
		setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
	};

	const handlePodFiles = (model, options) => {
		let updatedData = { ...model } ?? {};
		if (options?.key === "file_id") {
			// Options contain position of changed item in the list of all docs
			const itemPos = options?.pos;
			// Nested "options" contain information related to field that was updated in one of the "pattern" componet items
			const fileName = options?.options?.metadata?.path;
			// Only updating if description is not present yet
			if (model.pod_files[itemPos].description?.length === 0 && fileName?.length > 0) {
				updatedData.pod_files = [...updatedData.pod_files];
				updatedData.pod_files[itemPos] = { ...updatedData.pod_files[itemPos] };
				// Overriding description with file name
				updatedData.pod_files[itemPos].description = fileName;
				updatedData.pod_files[itemPos].analize = { status: "PROGRESS", metadata: {} };
			}
		}
		setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
	};

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col p-10 items-center justify-center">
				<Typography color="error" className="flex pt-10 pb-8 items-center justify-center">
					{error}
				</Typography>
			</div>
		);
	}

	if (!data.original) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
					Loading...
				</Typography>
			</div>
		);
	}

	const showParsingPopUp = !isApplied && uploadedFile && !isPublicView;
	return (
		<div className={"flex relative w-full " + (nativeMobile ? " h-full  " : "")}>
			<ParsingPopup
				ref={parsingPopupRef}
				dataIds={dataIds}
				uploadedFile={uploadedFile}
				showParsingPopUp={showParsingPopUp}
				handleApplyParsing={handleApplyParsing}
				handleUploadDocument={handleUploadDocument}
			/>
			<div
				style={showParsingPopUp || processing ? { pointerEvents: "none", opacity: 0.6 } : {}}
				// Lasrge screen dialog scroll will be handled inside of the form since
				// form supports side pannels that can be scrolled independently
				className={
					" w-full " + (nativeMobile ? " h-full " : "") + (!smallScreen && showRCPreview ? classes.content : "")
				}
			>
				<SmarthopFormView
					// Trick to create new UI object, creation new
					// is much faster than revalidation old component
					key={"form_" + smallScreen}
					mode={dataIds?.historyData ? "COMPARE" : "EDIT"}
					content={content}
					data={data.original}
					dataIds={dataIds}
					nativeMobile={nativeMobile}
					// Allows to track changes of any field (specific fileds can be specified to improve performance)
					trackChangedFields={["ALL"]}
					// Saving when data is changes model
					onChangeCommitted={(model, key, _, options) => {
						// workaround for rate on permission
						if (!hasRatesOnPermission) {
							const dOriginal = data.original;
							if (dOriginal.rate_original) model.rate = data?.edited?.rate ?? dOriginal.rate_original;
							if (dOriginal.profit_original) model.profit = data?.edited?.profit ?? dOriginal.profit_original;
							if (dOriginal.rpm_original) model.rpm = data?.edited?.rpm ?? dOriginal.rpm_original;
						}
						setData({ ...data, edited: model });

						if (
							key === "current_location" ||
							key === "pickup_address" ||
							key === "delivery_address" ||
							(key === "locations" &&
								options &&
								(options.action === "ADDED" ||
									options.action === "REMOVED" ||
									options.action === "MOVE_UP" ||
									options.action === "MOVE_DOWN" ||
									(options.action === "EDIT" && options.key === "location")))
						) {
							const changedAddress = options ? options.model?.[options.key] : model?.[key];
							if (changedAddress || options?.action === "REMOVED") {
								handledLocationsUpdated(model, changedAddress);
							}
						}
						if (key === "rate" || key === "emptymiles" || key === "miles") {
							handledRateUpdated(model);
						}
						if (key === "carrier") {
							handledCarrierUpdated(model);
						}
						if (key === "truck") {
							handledTruckUpdated(model);
						}

						const compare = data.edited ?? data.original;
						if (key === "truck_trailer") {
							if (compare.truck && compare?.truck__view?.metadata?.truck?.trailerId !== model.truck_trailer) {
								return setChangeTrailer(true);
							}
							setChangeTrailer(false);
						}
						if (key === "driver") {
							if (compare.truck && data?.original?.truck__view?.metadata?.truck?.driver !== model?.driver) {
								return setChangeDriverTruck(true);
							}
							setChangeDriverTruck(false);
						}
						if (key === "driver_secondary") {
							if (
								compare.truck &&
								data?.original?.truck__view?.metadata?.truck?.driver_secondary !== model?.driver_secondary
							) {
								if (!data?.original?.driver_secondary__view && !model?.driver_secondary) {
									return setChangeSecondaryDriverTruck(false);
								}
								return setChangeSecondaryDriverTruck(true);
							}
							setChangeSecondaryDriverTruck(false);
						}
						if (key === "rate_con_file") {
							handledRCUpdated(model);
						}
						if (key === "suggested_empty_address") {
							handledSuggestedAddressUpdated(model);
						}
						if (key === "tonu") {
							handledTonuUpdated(model);
						}
						if (key === "broker") {
							handleBrokerUpdate(model);
						}
						if (key === "status") {
							handleStatus(model);
						}
						if (key === "accessorials" && options?.action === "EDIT" && options?.key === "type") {
							let value = options?.model[options?.key];
							const newRule = value === "Detention" || value === "Layover" ? "CHARGE_PERSENTAGE" : "NO_FEE";
							let updatedData = { ...model } ?? {};
							updatedData.accessorials[options.pos].billing_rule = newRule;
							setData({ original: { ...data?.original, ...updatedData }, edited: updatedData });
						}
						if (key === "pod_files") {
							handlePodFiles(model, options);
						}
						if (_KEYS_AFFECT_SAVE_BROKERS.includes(key)) {
							if (key === "broker") return setChangeBroker([]);
							if (
								(!data?.original[key] &&
									(model[key] === "" || !model[key] || (typeof model[key] !== "number" && !model?.[key]?.length))) ||
								notChangeKey(data?.original[key], model[key])
							)
								return setChangeBroker(changeBrokerKeys.filter((broker) => broker !== key));
							setChangeBroker([...changeBrokerKeys, key]);
						}
					}}
					onSubmit={() => onSubmit()}
				/>
				<SmarthopConfirmDialog
					open={!!dialogConfirm?.flag}
					title={dialogConfirm?.title}
					message={dialogConfirm?.message}
					onClose={() => {
						revisionActionsRef.current[dialogConfirm?.type] = false;
						onSubmit();
					}}
					onAccept={() => {
						revisionActionsRef.current[dialogConfirm?.type] = true;
						onSubmit();
					}}
					otherActions={
						dialogConfirm?.type !== "SEND_DRIVER_NOTIFICATION"
							? [
									{
										onClick: () => setDialogConfirm({ flag: false, message: null, type: null }),
										label: "Cancel",
									},
							  ]
							: []
					}
					acceptMsg={dialogConfirm?.acceptMsg ?? "Yes"}
					closeMsg={"No"}
				/>

				{tripInvoiceWarnings?.length && (
					<WarningConfirmDialog
						open={isWarningDialogOpen}
						warnings={tripInvoiceWarnings}
						onAccept={onAcceptWarningChanges}
						onClose={cleanTripInvoiceWarnings}
					/>
				)}

				<PaywallDialog
					data={paywallDialog}
					onHandlerPaywall={() => {
						setDialogConfirm({ flag: false, message: null, type: null });
						setPaywallDialog({});
					}}
					onHandlerClosePaywall={() => setPaywallDialog({})}
				/>
			</div>
		</div>
	);
});

export default TripEditView;
