import { Typography, Icon } from "@material-ui/core";
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

const getTitle = (title, style) => {
	return <Typography className={`text-13 -mb-10 -ml-8 text-black font-semibold ${style}`}>{title}</Typography>;
};

const getColor = (metadatas) => {
	if (!metadatas) return null;

	const metadata = metadatas.find((metadata) => metadata.document_type === "Bill of Lading");
	if (!metadata) return "text-grey-600";

	const totalpages = metadata.pages.length;
	const pct_signed = (metadata.checks.n_signed_pages * 100) / totalpages;
	const pct_stamps = (metadata.checks.n_stamped_pages * 100) / totalpages;
	const pct_handwriting = (metadata.checks.n_handwritten_pages * 100) / totalpages;
	const pct_ai = (metadata.checks.n_pages_ai * 100) / totalpages;
	const bol_score = metadata.checks.bol_rc_comparison_score;

	let color;
	if (pct_signed > 80 && pct_stamps > 50 && pct_ai > 50 && bol_score > 0.5) {
		color = "text-green";
	} else if (pct_signed === 0 || pct_stamps === 0 || pct_ai === 0 || bol_score < 0.2) {
		color = "text-red";
	} else {
		color = "text-orange";
	}

	return color;
};

const STATUS = {
	DONE: {
		icon: "image_search",
	},
	ERROR: {
		icon: "help_outline",
		message: "File not analize.",
	},
	PROGRESS: {
		icon: "access_time",
		message: "Analysis in process...",
	},
};

const FilesIconAnalize = (props) => {
	const data = props.data;
	const style = props.style;
	const status = data?.status;
	const metadatas = data?.metadata?.metadata;

	let filesContent = {};
	let filesItems = [];
	metadatas?.forEach(function (metadata, i) {
		const isBOL = metadata?.document_type === "Bill of Lading";

		if (i > 0) {
			filesItems.push({ key: `divider_${i}`, type: "divider", divider: { classes: { root: "bg-transparent" } } });
		}

		if (metadata.document_type) {
			filesContent[`document_type_${i}`] = metadata.document_type;
			filesItems.push({ type: "message", builder: () => getTitle(metadata.document_type, "mt-16") });
		}
		if (metadata.pages) {
			filesContent[`total_pages_${i}`] = metadata.pages.length;
			filesItems.push({ key: `total_pages_${i}`, label: "Total Pages", type: "text" });
		}
		if (metadata.checks.n_pages_ai || metadata.checks.n_pages_ai === 0) {
			const style = { preview: { classes: { text: metadata.checks.n_pages_ai === 0 ? " text-red " : "" } } };

			filesContent[`n_pages_ai_${i}`] = String(metadata.checks.n_pages_ai);
			filesItems.push({
				key: `n_pages_ai_${i}`,
				label: "Visible Pages",
				type: "text",
				description: metadata.checks.n_pages_ai_explanation ?? null,
				descriptionShowPreview: true,
				component: style,
			});
		}
		if (metadata.checks.n_signed_pages || metadata.checks.n_signed_pages === 0) {
			const style = { preview: { classes: { text: metadata.checks.n_signed_pages === 0 ? " text-red " : "" } } };

			filesContent[`n_signed_pages_${i}`] = String(metadata.checks.n_signed_pages);
			filesItems.push({ key: `n_signed_pages_${i}`, label: "Signed Pages", type: "text", component: style });
		}
		if (metadata.checks.n_handwritten_pages || metadata.checks.n_handwritten_pages === 0) {
			const style = { preview: { classes: { text: metadata.checks.n_handwritten_pages === 0 ? " text-red " : "" } } };

			filesContent[`n_handwritten_pages_${i}`] = String(metadata.checks.n_handwritten_pages);
			filesItems.push({ key: `n_handwritten_pages_${i}`, label: "Handwritten Pages", type: "text", component: style });
		}
		if (metadata.checks.n_stamped_pages || metadata.checks.n_stamped_pages === 0) {
			const style = { preview: { classes: { text: metadata.checks.n_stamped_pages === 0 ? " text-red " : "" } } };

			filesContent[`n_stamped_pages_${i}`] = String(metadata.checks.n_stamped_pages);
			filesItems.push({ key: `n_stamped_pages_${i}`, label: "Stamped Pages", type: "text", component: style });
		}

		if (isBOL && metadata.bol_rc_comparison_checks) {
			const comparison = metadata.bol_rc_comparison_checks;
			Object.keys(comparison).forEach((key) => {
				const field = `${key}_${i}`;
				const data = comparison[key];

				let label = key?.replace("_comparison", "").replaceAll("_", " ");
				label = label.charAt(0).toUpperCase() + label.slice(1);

				filesContent[field] = data.check;
				filesItems.push({
					key: `${field}`,
					label: label,
					type: "text",
					description: data.explanation,
					descriptionShowPreview: true,
					component: {
						preview: {
							classes: {
								text:
									data.check === "Yes"
										? " text-green-600 "
										: data.check === "No"
										? " text-red-600 "
										: " text-orange-600 ",
							},
						},
					},
				});
			});
		}
		if (!isBOL && metadata.pages?.length === 1) {
			filesItems.push({
				type: "message",
				builder: () => (
					<Typography className={"text-13 text-black mt-12 -mx-3"}>{metadata.pages[0].page_description}</Typography>
				),
			});
		}
	});

	if (!data?.status) {
		return null;
	}

	return (
		<TooltipClickAwayView
			classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300 ml-10" }}
			title={
				<div className="px-10 pb-12">
					{status === "DONE" ? (
						<SmarthopFormView
							content={{
								items: [
									{
										type: "message",
										builder: () => getTitle("Document(s) Summary"),
									},
									...filesItems,
								],
							}}
							data={filesContent}
							mode={"VIEW"}
							overrides={{ ALL_EDITABLE: { component: { preview: { variant: "row" } } } }}
						/>
					) : (
						<Typography className={"text-13 text-black pt-12 pb-0 px-10"}>{STATUS[status].message}</Typography>
					)}
				</div>
			}
		>
			<Icon
				className={`text-16 ml-8 ${status !== "DONE" || !metadatas ? "text-orange" : getColor(metadatas)} ${
					style?.icon
				}`}
			>
				{STATUS[status].icon}
			</Icon>
		</TooltipClickAwayView>
	);
};

export default FilesIconAnalize;
